<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#fff">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{
                  get_seller.new
                    ? "Novo Vendedor"
                    : get_seller.name
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="get_seller._id">
              <v-btn
                color="red"
                @click="delete_seller_dialog(get_seller)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div class="windows-style-content expande-horizontal wrap">
      <v-tabs
        class="fonte"
        v-model="tab"
        show-arrows
        dark
        grow
        background-color="deep-purple"
      >
        <v-tab class="font-weight-bold">Informações</v-tab>
        <v-tab @click="getSellerSales" class="font-weight-bold">Vendas</v-tab>
        <v-tab @click="getSellerComission" class="font-weight-bold">Comissões do vendedor</v-tab>
        <v-tab @click="getTeamSupervisor" v-if="get_seller.is_supervisor" class="font-weight-bold">Equipe</v-tab>
        <v-tab @click="getComissionSupervisor" v-if="get_seller.is_supervisor" class="font-weight-bold">Comissões do supervisor</v-tab>
      </v-tabs>
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>

      <div
        v-show="tab === 0"
        class="expande-horizontal wrap py-6 pb-0"
        style="min-height: 340px;"
      >
        <v-flex xs12>
          <v-form ref="form">
            <!-- <ModalSendArchive /> -->
            <v-flex v-if="get_seller._id" class="px-3 pb-6" xs12>
              <v-list-item style="border-radius: 6px; border: 1px solid #333; background: #f2f2f2;" class="fonte">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="link-seller">
                      https://app.idemsaude.com.br/#/comprar-plano/essencial?s_id={{ get_seller.id_seller }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Link do vendedor
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small color="deep-purple" dark @click="copyLink">copiar</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-flex>
            <v-flex class="px-3" xs12>
              <div class="expande-horizontal">
                <v-switch color="deep-purple" label="Promover a Supervisor"
                  v-model="get_seller.is_supervisor"
                ></v-switch>
              </div>
            </v-flex>
            <v-flex v-if="!get_seller.is_supervisor" class="px-3" xs12>
              <div class="expande-horizontal fonte">
                <v-autocomplete 
                  outlined
                  :items="supervisors"
                  dense
                  item-text="name"
                  item-value="_id"
                  color="deep-purple" label="Definir Supervisor"
                  v-model="get_seller.my_supervisor"
                ></v-autocomplete>
              </div>
            </v-flex>
            <div class="expande-horizontal wrap">
              <v-flex class="px-3" xs12 md6>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Nome
                </span>
                <v-text-field
                  v-model="get_seller.name"
                  dense
                  solo
                  flat
                  outlined
                  clearable
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                  label="ex: Roberto"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-3" xs12 md6>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Email
                </span>
                <v-text-field
                  v-model="get_seller.email"
                  dense
                  solo
                  flat
                  outlined
                  clearable
                  :color="$theme.primary"
                  label="ex: email@gmail.com"
                ></v-text-field>
              </v-flex>
            </div>

            <div class="expande-horizontal">
              <v-flex class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  CPF
                </span>
                <v-text-field
                  v-model="get_seller.cpf"
                  dense
                  solo
                  flat
                  outlined
                  v-mask="['###.###.###-##']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 000.000.000-00"
                ></v-text-field>
              </v-flex>
            </div>
          </v-form>
        </v-flex>
      </div>
      <!-- Vendas do vendedor -->
      <div v-if="tab == 1" class="expande-horizontal">
        <v-flex xs12>
          <span class="fonte" v-if="loading">Carregando...</span>
          <v-list>
            <template v-for="(sale, index) in seller_sales">
              <v-list-item
                @click="open(sale)"
                style="border-radius: 6px;"
                :key="sale._id"
                class="fonte pl-2 pr-1 animate__animated animate__fadeInUp"
              >
                <v-avatar color="#f2f2f2" class="mr-2">
                  <v-icon :color="$theme.primary">mdi-storefront-plus</v-icon>
                </v-avatar>
                <v-list-item-content class="">
                  <v-list-item-title style="font-size: 12pt;">
                    {{ $helper.formataSaldo(sale.plan_id.value_cents / 2) }} - {{ sale.plan_id.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    <v-icon size="14" :color="$theme.primary">mdi-account-circle-outline</v-icon> {{ sale.user_id.nome }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon size="14" :color="$theme.primary">mdi-calendar-clock</v-icon>
                    <span class="fonte fonteMini">
                      {{ $moment(sale.created_at).format('DD/MM/YY [às] HH:mm') }}
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span class="fonte" :class="sale.commission_ready ? 'green--text' : 'orange--text'">
                      <v-icon size="10" :color="sale.commission_ready ? 'green' : 'orange'">mdi-circle</v-icon>
                      {{ sale.comission_ready ? 'Comissão paga' : 'Comissão aguardando pagamento do cliente' }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
      </div>
      <!-- Comissões do vendedor -->
      <div v-if="tab == 2" class="expande-horizontal">
        <v-flex xs12>
          <span class="fonte" v-if="loading">Carregando...</span>
          <v-list>
            <template v-for="(comission, index) in seller_comissions">
              <v-list-item :key="comission._id">
                <v-list-item-title>
                  {{ comission.value }}
                </v-list-item-title>
              </v-list-item>
              <v-divider :key="`seller-comission-${index}`"></v-divider>
            </template>
          </v-list>
        </v-flex>
      </div>
      <!-- Equipe -->
      <div v-if="tab == 3" class="expande-horizontal">
        <v-flex xs12>
          <span class="fonte" v-if="loading">Carregando...</span>
          <v-list>
            <template v-for="(seller, index) in team">
              <v-list-item :key="seller._id">
                <v-list-item-title>
                  {{ seller.name }}
                </v-list-item-title>
              </v-list-item>
              <v-divider :key="`seteam-${index}`"></v-divider>
            </template>
          </v-list>
        </v-flex>
      </div>
      <!-- Comissões do supervisor -->
      <div v-if="tab == 4" class="expande-horizontal">
        <v-flex xs12>
          <span class="fonte" v-if="loading">Carregando...</span>
          <v-list>
            <template v-for="(comission, index) in supervisor_comissions">
              <v-list-item :key="comission._id">
                <v-list-item-title>
                  {{ comission.value }}
                </v-list-item-title>
              </v-list-item>
              <v-divider :key="`supervisor-comission-${index}`"></v-divider>
            </template>
          </v-list>
        </v-flex>
      </div>
    </div>

    <div class="expande-horizontal windows-style-bottom">
      <v-btn large dark @click="valida_form" color="deep-purple">
        <span class="fonte"> Salvar </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      tab: 0,
      sales: [],
      seller_comissions: [],
      supervisor_comissions: [],
      team: [],
      seller_sales: [],
      loading: false,
    };
  },
  computed: {
    supervisors() {
      const supervisors = this.get_sellers.docs.filter(item => {
        return item.is_supervisor;
      });
      return supervisors;
    },
    ...mapGetters([
      "get_seller",
      "get_sellers",
      "get_modal_view_seller",
    ]),
  },
  methods: {
    getSellerSales() {
      this.loading = true;
      this.$run('seller/view-seller-subscriptions', {
        _id: this.get_seller.id_seller
      }, 'erp').then(res => {
        this.loading = false;
        this.seller_sales = res.data;
      })
    },
    getTeamSupervisor() {
      this.loading = true;
      this.$run('seller/list-team-supervisor', {
        _id: this.get_seller._id
      }, 'erp').then(res => {
        this.loading = false;
        this.team = res.data;
      })
    },
    getComissionSupervisor() {
      this.loading = true;
      this.$run('seller/view-supervisor-sales', {
        _id: this.get_seller._id
      }, 'erp').then(res => {
        this.loading = false;
        this.supervisor_comissions = res.data;
      })
    },
    getSellerComission() {
      this.loading = true;
      this.$run('seller/view-seller-sales', {
        _id: this.get_seller._id
      }, 'erp').then(res => {
        this.loading = false;
        this.seller_comissions = res.data;
      })
    },  
    copyLink() {
      const el = document.createElement("textarea");
      el.value = `https://app.idemsaude.com.br/#/comprar-plano/essencial?s_id=${this.get_seller.id_seller}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.createGlobalMessage({
        message: "O link copiado para sua área de transferência!",
        type: "success",
        timeout: 5000
      });
    },
    ...mapActions([
      "create_seller",
      "update_seller",
      "close_modal_view_seller",
      "createGlobalMessage"
    ]),
    backToList() {
      this.$store.commit("set_seller", {});
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_seller._id
          ? this.update_seller()
          : this.create_seller();
      }
    },
  },
};
</script>

<style>
.item-transac {
  background: #f0f0f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>